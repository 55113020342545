// Generated by Framer (d19f530)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["C_eenTfgJ", "ah_wn3VHX", "ibhaCzkCq", "V94E5RVKA"];

const variantClassNames = {ah_wn3VHX: "framer-v-cg0534", C_eenTfgJ: "framer-v-51fqs0", ibhaCzkCq: "framer-v-1hsy6k3", V94E5RVKA: "framer-v-1b9q9br"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "C_eenTfgJ", "Variant 2": "ah_wn3VHX", "Variant 3": "ibhaCzkCq", "Variant 4": "V94E5RVKA"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "C_eenTfgJ", link: vxgqk11Lr, title: PtUK9wvEI = "MORE ABOUT US", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "C_eenTfgJ", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterfyv41k = activeVariantCallback(async (...args) => {
setVariant("ah_wn3VHX")
})

const onMouseLeaveeuduw8 = activeVariantCallback(async (...args) => {
setVariant("ibhaCzkCq")
})

const onAppear1iwcxnm = activeVariantCallback(async (...args) => {
setVariant("V94E5RVKA")
})

const onAppearsf064d = activeVariantCallback(async (...args) => {
setVariant("C_eenTfgJ")
})

useOnVariantChange(baseVariant, {ibhaCzkCq: onAppear1iwcxnm, V94E5RVKA: onAppearsf064d})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-MdykM", classNames)} style={{display: "contents"}}>
<Link href={vxgqk11Lr}><motion.a {...restProps} className={`${cx("framer-51fqs0", className)} framer-pi5lgq`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"C_eenTfgJ"} onMouseEnter={onMouseEnterfyv41k} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({ah_wn3VHX: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeaveeuduw8}, ibhaCzkCq: {"data-framer-name": "Variant 3"}, V94E5RVKA: {"data-framer-name": "Variant 4"}}, baseVariant, gestureVariant)}><motion.div className={"framer-10eg61g"} layoutDependency={layoutDependency} layoutId={"lqzHY1aLf"} style={{backgroundColor: "rgb(23, 23, 23)"}} transition={transition}/><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 style={{"--font-selector": "SW50ZXItU2VtaUJvbGQ=", "--framer-font-family": "\"Inter-SemiBold\", \"Inter\", sans-serif", "--framer-font-weight": "600", "--framer-letter-spacing": "-0.04em", "--framer-line-height": "1.15em", "--framer-text-alignment": "center"}}>MORE ABOUT US</motion.h2></React.Fragment>} className={"framer-wxjz1i"} fonts={["Inter-SemiBold"]} layoutDependency={layoutDependency} layoutId={"cB6XcWYF4"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={PtUK9wvEI} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-MdykM [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MdykM .framer-pi5lgq { display: block; }", ".framer-MdykM .framer-51fqs0 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 10px 0px 10px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-MdykM .framer-10eg61g { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; top: 36px; width: 100%; z-index: 1; }", ".framer-MdykM .framer-wxjz1i { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-MdykM .framer-51fqs0 { gap: 0px; } .framer-MdykM .framer-51fqs0 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-MdykM .framer-51fqs0 > :first-child { margin-top: 0px; } .framer-MdykM .framer-51fqs0 > :last-child { margin-bottom: 0px; } }", ".framer-MdykM.framer-v-cg0534 .framer-10eg61g { bottom: 0px; left: 126px; right: -1px; width: unset; }", ".framer-MdykM.framer-v-1hsy6k3 .framer-10eg61g { bottom: 0px; left: -4px; right: 129px; width: unset; }", ".framer-MdykM.framer-v-1b9q9br .framer-10eg61g { bottom: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 38.5
 * @framerIntrinsicWidth 125.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ah_wn3VHX":{"layout":["auto","auto"]},"ibhaCzkCq":{"layout":["auto","auto"]},"V94E5RVKA":{"layout":["auto","auto"]}}}
 * @framerVariables {"vxgqk11Lr":"link","PtUK9wvEI":"title"}
 */
const FramerIgfwhE9GV: React.ComponentType<Props> = withCSS(Component, css, "framer-MdykM") as typeof Component;
export default FramerIgfwhE9GV;

FramerIgfwhE9GV.displayName = "comp link";

FramerIgfwhE9GV.defaultProps = {height: 38.5, width: 125.5};

addPropertyControls(FramerIgfwhE9GV, {variant: {options: ["C_eenTfgJ", "ah_wn3VHX", "ibhaCzkCq", "V94E5RVKA"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4"], title: "Variant", type: ControlType.Enum}, vxgqk11Lr: {title: "Link", type: ControlType.Link}, PtUK9wvEI: {defaultValue: "MORE ABOUT US", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerIgfwhE9GV, [])